import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('addyPlusBase', ['corporationsForSideMenu']),
    corporationId() {
      return this.$route.params.corporationId
    },
    propertyId() {
      return this.$route.params.propertyId
    },
    propertyName() {
      return this.corporationsForSideMenu[this.corporationId]?.properties?.find((prop) => prop.id === this.propertyId)?.name
    },
  },
}
