<template>
  <div class="due-dilligence-wrapper">
    <Permission/>
    <Breadcrumb/>
    <div class="title">Due Diligence Uploads</div>
    <div class="subtitle">{{ propertyName }}</div>
    <Tabs/>
    <DropNUpload
      :key="dropNUploadComponentKey"
      :editable="canCreate"
      feature="propDueDiligence"
      @onSubmit="onSubmit"
    ></DropNUpload>
    <b-progress
      :value="loadedPercentage"
      type="is-blue"
      format="percent"
      show-value
      v-show="showProgressBar"
    ></b-progress>
    <section class="file-list-wrapper">
      <div v-for="(category, categoryName) in fileList" :key="categoryName">
        <h3 class="category-name has-text-weight-bold pt-5 pb-3">{{categoryName}}</h3>
        <File
          v-for="file in category"
          :key="file.id"
          :data="file"
          :permissions="permissions.assetDueDiligence"
          ownable="property"
          @onFileDeletion="openDeleteModal"
          @onFileUpdate="onFileUpdate"
          @onFilePreview="onFilePreview"
        ></File>
      </div>
    </section>
    <FilePreviewModal :fileUrl="previewUrl" :fileType="previewFileType" @closeModal="onFilePreviewModalHide"></FilePreviewModal>
    <Flash title="Congrats" :content="flashContent" :show="showFlash" :hideFlash="hideFlash"></Flash>
    <ConfirmModal
      title="Delete file?"
      text="Are you sure you want to delete this file? This action cannot be undone."
      :showModal="fileIdToDelete !== null"
      continueButtonText="Continue to delete"
      :hasCloseIcon="false"
      :hasClickableBackground="false"
      :hasCancelButtonOnLeft="true"
      @closeModal="fileIdToDelete = null"
      @cancelAction="fileIdToDelete = null"
      @doContinue="continueDeleteAction"
    ></ConfirmModal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import propertyMixin from '@utils/mixins/addy-plus-property-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Tabs from '../Tabs.vue'
import DropNUpload from '@components/upload/drop-n-upload.vue'
import File from './File.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import FilePreviewModal from '@components/modal/file-preview-modal.vue'
import fileTypeMixin from '@utils/mixins/file-type-mixin.js'
import { fetchPropertyDueDiligenceFiles, handlePropertyDueDiligenceFiles } from '@api/addy-plus/properties'
import { fetchDocUrl } from '@api/common'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import ConfirmModal from '@components/modal/continue-or-cancel-modal.vue'

export default {
  title: 'Due Diligence Uploads',
  mixins: [pageTitleMixin, propertyMixin, fileTypeMixin],
  components: {
    Permission,
    Tabs,
    DropNUpload,
    File,
    Flash,
    FilePreviewModal,
    Breadcrumb,
    ConfirmModal,
  },
  data() {
    return {
      dropNUploadComponentKey: 0,
      fileList: {},
      showProgressBar: false,
      loadedPercentage: 0,
      showFlash: false,
      timer: null,
      flashContent: '',
      previewUrl: '',
      previewFileType: 'image',
      fileIdToDelete: null,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    canCreate() {
      return this.permissions.assetDueDiligence?.includes('create')
    },
  },
  mounted() {
    this.fetchFiles()
  },
  methods: {
    fetchFiles() {
      fetchPropertyDueDiligenceFiles(this.propertyId).then((res) => {
        if (!res.success) return
        const { encryptedUploads } = res.data
        this.fileList = encryptedUploads
        this.handleAutoScroll()
      })
    },
    onSubmit(formData) {
      this.showProgressBar = true
      handlePropertyDueDiligenceFiles(this.propertyId, formData, this.onUploadProgress).then((res) => {
        if (!res.success) return
        this.onFlashShowing('uploaded')
        this.dropNUploadComponentKey++
        this.fetchFiles()
      }).finally(() => {
        this.showProgressBar = false
      })
    },
    onFlashShowing(type) {
      this.flashContent = `Successfully ${type}!`
      this.showSuccessFlash()
    },
    showSuccessFlash() {
      this.showFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.showFlash = false
    },
    onFileDeletion(fileId) {
      const payload = { property: { '0': { id: fileId, _destroy: true } } }
      handlePropertyDueDiligenceFiles(this.propertyId, payload, () => {}).then((res) => {
        if (!res.success) return
        this.onFlashShowing('deleted')
        this.fetchFiles()
      })
    },
    onFileUpdate(payload) {
      handlePropertyDueDiligenceFiles(this.propertyId, payload, () => {}).then((res) => {
        if (!res.success) return
        this.onFlashShowing('updated')
        this.fetchFiles()
      })
    },
    onFilePreview({ hashid, fileName }) {
      this.previewFileType = this.getFileTypeFromName(fileName)
      if (!['image', 'pdf'].includes(this.previewFileType)) {
        this.previewUrl = 'use_default' // previewUrl is only used to toggle the preview modal for doc or excel
        return
      }

      fetchDocUrl({
        hashid,
        model_name: 'EncryptedUpload',
        document_type: 'file',
      }).then((res) => {
        if (!res.success) return
        this.previewUrl = res.data.url
      })
    },
    onFilePreviewModalHide() {
      this.previewUrl = ''
    },
    handleAutoScroll() {
      const targetId = this.$route.query.target
      if (targetId) {
        this.$nextTick(() => {
          const target = document.getElementById(targetId)
          target.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    openDeleteModal(fileId) {
      this.fileIdToDelete = fileId
    },
    continueDeleteAction() {
      this.onFileDeletion(this.fileIdToDelete)
      this.fileIdToDelete = null
    },
  },
}
</script>
<style lang="scss" scoped>
.progress-wrapper {
  margin-top: 30px;
}
.due-dilligence-wrapper {
  padding-bottom: 60px;
  .file-list-wrapper {
    font-size: 16px;
  }
  .category-name {
    font-size: 22px;
    border-bottom: 2px solid #f5f5f5;
  }
}
</style>
